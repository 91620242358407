<template>
  <div style="margin:15px;">
      <v-card hover ripple @click="openTVShow">
          <v-img
              :src="'https://image.tmdb.org/t/p/w500' + poster"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
            >
              <v-card-title v-text="title"></v-card-title>
              <v-card-subtitle>{{ year }}</v-card-subtitle>
            </v-img>
      </v-card>
  </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        year: {
            type: Number,
            required: true
        },
        poster: {
            type: String,
            required: false
        }
    },
    methods: {
        openTVShow() {
            this.$router.push("/tvshow/" + this.id);
        }
    }
};
</script>

<style>
</style>