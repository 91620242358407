<template>
  <div style="width:100%;padding:10px;">
    <v-layout row wrap style="margin:0 0 10px 0;">
      <v-flex xs6 sm6>
        <h1>{{ $t("tvshows.header") }}</h1>
      </v-flex>
    </v-layout>
    <TVShowsSearch></TVShowsSearch>
    <TVShowsLibrary></TVShowsLibrary>
  </div>
</template>

<script>
import TVShowsSearch from "../components/tvshows/TVShowsSearch";
import TVShowsLibrary from "../components/tvshows/TVShowsLibrary";

export default {
  components: {
    TVShowsSearch,
    TVShowsLibrary
  }
};
</script>

<style>
</style>