<template>
  <div>
      <v-text-field solo v-model="tvshows_search" @keydown="startSearch" :label="$t('tvshows.search_for_tvshows')"></v-text-field>
      <div class="results">
          <v-layout row wrap>
              <v-flex xs6 sm4 md3 lg2 v-for="tvshow in tvshows" :key="tvshow.id">
                  <TVShowsCard :id="tvshow.id" :title="tvshow.name" :year="(new Date(tvshow.first_air_date)).getFullYear()" :poster="tvshow.poster_path"></TVShowsCard>
              </v-flex>
          </v-layout>
      </div>
  </div>
</template>

<script>
import axios from "axios";
import { THEMOVIEDB_API_KEY } from "../../keys";

import TVShowsCard from "./TVShowsCard";

export default {
    data() {
        return {
            tvshows_search: "",

            tvshows: []
        }
    },
    methods: {
        startSearch(e) {
            var keycode = e.keyCode ? e.keyCode : e.which;
            if (keycode == '13' && this.tvshows_search !== "") {
                this.searchTVShow(this.tvshows_search);
            }
        },
        async searchTVShow(query) {
            var response = await axios({
                url: "https://api.themoviedb.org/3/search/tv?api_key=" + THEMOVIEDB_API_KEY + "&query=" + encodeURI(query) + "&language=de"
            });

            if (response.status === 200) {
                this.tvshows = response.data.results;
            }
        }
    },
    components: {
        TVShowsCard
    }
}
</script>

<style>

</style>