<template>
  <div>
    <h2>{{ $t("tvshows.library") }}</h2>
    <v-layout row wrap>
      <v-flex xs6 sm4 md3 lg2 v-for="tvshow in tvshows" :key="tvshow.id">
        <TVShowsCard
          :id="tvshow.data.tmdb_id"
          :title="tvshow.data.tvshow_title"
          :year="(new Date(tvshow.data.tvshow_first_air_date)).getFullYear()"
          :poster="tvshow.data.tvshow_poster_path"
        ></TVShowsCard>
      </v-flex>
    </v-layout>
    <v-card v-intersect="infiniteScrolling"></v-card>
  </div>
</template>

<script>
import TVShowsCard from "./TVShowsCard";
import { storage } from "../../schmucklicloud";
export default {
  data() {
    return {
      tvshows: [],

      last_tvshows_length: 0
    };
  },
  mounted() {
    this.loadLibraryTVShows(this.tvshows.length, 15);
  },
  methods: {
    infiniteScrolling() {
      if (this.last_tvshows_length === this.tvshows.length) {
        return;
      } else {
        this.last_tvshows_length = this.tvshows.length;
        setTimeout(
          function() {
            this.loadLibraryTVShows(this.tvshows.length, 15);
          }.bind(this),
          1000
        );
      }
    },
    async loadLibraryTVShows(start, limit) {
      var response = await storage.getAll(
        "tvshows",
        [
          {
            column: "watched_year",
            order: "desc"
          },
          {
            column: "watched_month",
            order: "desc"
          }
        ],
        start,
        limit
      );
      if (response.isOK) {
        if (response.data !== undefined) {
          this.tvshows = this.tvshows.concat(response.data);
        }
      }
    }
  },
  components: {
    TVShowsCard
  }
};
</script>

<style>
</style>